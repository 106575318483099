import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import Layout from "../components/layout/Layout"
import Header from "../components/layout/Header"

const NotFoundPage = ({ location }) => {
    useEffect(() => {
        navigate('/404', { replace: true })
    }, [])

    return (
        <Layout location={location}>
            <Header title="404: Not found"/>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Layout>
    )
}

export default NotFoundPage
